<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <h2>WELCOME</h2>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol
                >All available features can be accessed via the sidebar menu to
                the left.</CCol
              >
            </CRow>
            <CRow>
              <CCol
                >As clients and their data get added into the system
                functionality that wasn't visible before will begin to make
                itself available.</CCol
              >
            </CRow>
            <CRow>
              <CCol>
                <span style="color: rgba(0, 0, 0, 0.5)"
                  >If actions cannot be performed due to a lack of permissions
                  or data they will not be visible.</span
                >
              </CCol>
            </CRow>
            <CRow class="pt-4">
              <CCol>
                <h3>Workflows</h3>
              </CCol>
            </CRow>
            <CRow>
              <CCol
                >Workflows are a collection of tasks that are designed to be
                performed in a specific order.</CCol
              >
            </CRow>
            <CRow>
              <CCol
                >Depending on the output of the previous steps they will include
                escalation and remediation steps as well as various system
                tasks.</CCol
              >
            </CRow>
            <CRow class="pt-4">
              <CCol>
                <h3>Tasks</h3>
              </CCol>
            </CRow>
            <CRow>
              <CCol
                >A task is a single step in a Workflow. They can require user
                input, process data, or kick off a number of automated
                functions.</CCol
              >
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CCardGroup deck>
          <CCard>
            <CCardHeader>
              <h2>
                <MIcon name="fa-group" />
                <span class="pl-3">Clients</span>
              </h2>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol
                  >As clients get created in the system they will show up in the
                  clients menu in the left sidebar, as well as in the dropdown
                  menu in the upper right corner of the screen.</CCol
                >
              </CRow>
              <CRow class="pt-4">
                <CCol
                  >The client overview dashboard will give you quick access to
                  all the files uploaded and created during various workflow
                  processes and tasks.</CCol
                >
              </CRow>
            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader>
              <h2>
                <MIcon name="fa-inbox" />
                <span class="pl-3">My Activities</span>
              </h2>
            </CCardHeader>
            <CCardBody>
              <CRow class="pb-4">
                <CCol
                  >Listed below are tasks currently assigned to you which are
                  scheduled, escalated or failed.</CCol
                >
              </CRow>
              <CRow>
                <CCol>
                  <CDataTable
                    :items="recentActivities"
                    :fields="recentActivityFields"
                    :items-per-page="5"
                    pagination
                    striped
                    class="client-activities-table"
                  >
                    <template #status="{ item }">
                      <td>
                        <MIcon
                          v-if="item.statusIcon"
                          :name="item.statusIcon.name"
                          :class="item.statusIcon.color"
                        />
                      </td>
                    </template>
                    <template #clientCode="{ item }">
                      <td>
                        <router-link
                          :to="{
                            name: 'view-client',
                            params: { name: item.clientCode },
                          }"
                          >{{ item.clientCode }}</router-link
                        >
                      </td>
                    </template>
                    <template #title="{ item }">
                      <td>
                        <router-link
                          v-if="item.taskId"
                          :to="{
                            name: 'view-task',
                            params: { id: item.taskId },
                          }"
                          >{{ item.title }}</router-link
                        >
                        <div v-else>{{ item.title }}</div>
                      </td>
                    </template>
                    <template #action="{ item }">
                      <td>{{ item.action }}</td>
                    </template>
                    <template #endTime="{ item }">
                      <td>{{ $format.formatDate(item.taskTime) }}</td>
                    </template>
                  </CDataTable>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCardGroup>
      </CCol>
    </CRow>
  </div>
</template>
<style lang="scss">
.client-activities-table {
  .table-responsive {
    height: 300px;
    overflow-y: auto;
  }
}
</style>
<script>
import { mapGetters } from 'vuex';
import WorkflowTask from '@/WorkflowTask';

const ACTIVITY_FIELDS = [
  {
    key: 'status',
    label: '',
  },
  {
    key: 'clientCode',
    label: 'Client',
  },
  {
    key: 'title',
    label: 'Task',
  },
  {
    key: 'action',
    label: 'Status',
  },
  {
    key: 'endTime',
    label: 'Date',
  },
];

export default {
  name: 'Dashboard',
  computed: {
    ...mapGetters('tasks', ['activities']),
    recentActivities() {
      return this.activities;
    },
    recentActivityFields() {
      return ACTIVITY_FIELDS;
    },
  },
};
</script>
